@media only screen and (max-width: 1550px) {
  .institutes-slider .banner-main-title,
  .banner-main-title {
    @include fontSize(47px);
  }
  .institutes-slider .banner-suubmain-title,
  .banner-suubmain-title {
    @include fontSize(24px);
  }
}

@media only screen and (max-width: 1450px) {
  .page-content,
  .footer-container,
  .navbar-container,
  .main_container {
    width: 96%;
  }
  .main_container-1 {
    width: 98%;
    margin-left: 2%;
  }
  .institutes-slider .slick-prev {
    left: 3px;
    width: 25px;
    height: 25px;
  }
  .institutes-slider .slick-next {
    right: 3px;
    width: 25px;
    height: 25px;
  }
  .institutes-slider .slick-prev:before,
  .institutes-slider .slick-next:before {
    font-size: 25px;
  }
  .pricing-tab .Free-Lifetime-Access {
    margin-top: 102px;
  }
  .modal-content .form-block {
    margin-top: 20px;
  }
  .modal-content .mb-3 {
    margin-bottom: 14px !important;
  }
  .modal-content .social-signup {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .modal-content .signup-bg-img-stud {
    background-size: 101%;
  }
  .reset-pass .std-bold-radio .drop-down-outer {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1310px) {
  .error-msg-10 {
    @include fontSize(13px);
  }
}
@media only screen and (max-width: 1135px) {
  .institutes-slider .banner-main-title {
    @include fontSize(50px);
    padding-top: 100px;
    width: 100%;
  }
  .label.brochure-f-s .text-wrapper:before {
    top: 2px !important; 
}
  .institutes-slider .banner-suubmain-title {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 40px;
    @include fontSize(22px);
  }
  .download-btn-block {
    margin-top: -50px;
  }
  .banner-main-title {
    @include fontSize(50px);
    padding-top: 100px;
    width: 70%;
  }
  .banner-suubmain-title {
    width: 60%;
    padding-top: 30px;
    padding-bottom: 40px;
    @include fontSize(22px);
  }
  .home-bg {
    min-height: 520px;
    height: auto;
  }
  .show-icon-banner .banner-block {
    width: 55px;
    margin-right: 15px;
    img {
      width: 100%;
    }
  }
  .session_block {
    padding-top: 60px;
    padding-bottom: 60px;
    .text_block {
      .title_view {
        .title_icon {
          img {
            height: 50px;
            width: 50px;
          }
        }
        .title_Text {
          @include fontSize(20px);
        }
      }
      .list_view {
        .list_items {
          margin-top: 10px;
          @include fontSize(20px);
          .list_data {
            @include fontSize(16px);
          }
          .list_data1 {
            @include fontSize(16px);
          }
        }
      }
    }
    .video_block {
      .video_player {
        height: 250px;
        video {
          height: 250px;
        }
      }
    }
  }
  .navbar-container {
    .round-in-header {
      height: 80px;
      top: -45px;
      width: 80px;
    }
    .nav-menu {
      margin-right: 1rem;
      margin-left: 2rem;
    }
  }
  .profile-pic img {
    height: 80px;
    width: 80px;
  }
  .slider-card {
    height: 320px;
    min-height: 360px;
    .slider-text {
      @include fontSize(18px);
      min-height: 250px;
    }
  }
  .reviewer-name {
    @include fontSize(12px);
  }
  .footer-subscription {
    flex-direction: row-reverse;
    .footer-logo {
      width: 100%;
      margin-bottom: 35px;
    }
    .footer-menu-block {
      width: 100%;
    }
  }
  .footer-outer-block {
    background-position: 90px 230px;
  }
  .slick-slide-outer .slick-slide {
    padding: 0 10px;
    padding-top: 51px;
  }
  .faq {
    @include fontSize(22px);
  }
  .modal-1280 {
    min-width: 600px;
    .col-md-6,
    .col-md-7,
    .col-md-5 {
      width: 100%;
    }
  }
  .padding-lf-0 {
    display: none;
  }
  .slick-dots.slick-thumb.show-icon-banner {
    margin-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .text-lg-start {
    flex-direction: column-reverse !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-color: #ffa235;
    border-radius: 50%;
    padding: 16px;
    display: inline-block;
    height: 20px !important;
    width: 20px !important;
    background-size: 1rem 1rem;
  }
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1 !important;
  }

  .session_block-32145 {
    background: #e8eefe;
    // min-height: 100vh !important;
    display: flex;
    align-items: center;
  }
  .count-text {
    color: #232323 !important;
    font-family: "Roboto-Condensed";
    font-size: 1.7rem !important;
    font-style: normal;
    font-weight: 700;

    text-transform: capitalize;
  }
  .name-text {
    color: #848484 !important;
    leading-trim: both;
    text-edge: cap;
    font-family: "Montserrat-Medium";
    font-size: 0.9rem !important;
    font-style: normal;
  }
  .img-r {
    margin-right: 20px;
    width: 36px;
  }
.session_block-32145{
background:#E8EEFE; 
display: flex;
align-items: center;

}
  .session_block-32146 {
    background: #eafee8;
    display: flex;
    align-items: center;
  }
  .session_block-321467 {
    background: #fef0e8;
    display: flex;
    align-items: center;
  }
  .modal-1280 {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    margin: 0;
    .modal-content {
      padding: 15px;
    }
    .detail-block {
      padding: 0;
      padding-top: 10px;
      min-height: 460px;
    }
    .form-block {
      padding-right: 0px;
      margin-top: 20px;
    }
    .social-icon {
      width: 97px;
      height: 30px;
      margin: 0 5px;
    }
  }
  .institutes-slider .banner-main-title,
  .pricing-main-title {
    @include fontSize(20px);
    line-height: 25px;
    padding-top: 20px;
    width: 100%;
  }
  .institutes-slider .banner-suubmain-title {
    @include fontSize(13px);
    line-height: 15px;
    padding-top: 15px;
    padding-bottom: 20px;
    width: 100%;
  }
  .banner-main-title,
  .pricing-main-title {
    @include fontSize(20px);
    line-height: 25px;
    padding-top: 20px;
    width: 100%;
  }
  .banner-suubmain-title {
    @include fontSize(13px);
    line-height: 15px;
    padding-top: 15px;
    padding-bottom: 20px;
    width: 60%;
  }
  .slider-img {
    width: 100%;
  }
  .download-btn-block {
    flex-wrap: wrap;
    margin-top: -20px;
    .slider-text {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .download-btn {
      margin-bottom: 30px;
    }
  }
  .pricing_icon_img {
    width: 100%;
  }
  .pricing-icon-banner {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .pricing-icon-banner {
    .pricing-block {
      margin-bottom: 0px;
      .pricing-icon-img {
        height: 35px;
        width: 35px;
      }
      .pricing-sub-title {
        @include fontSize(13px);
        line-height: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
      }
    }
  }

  .col_6,
  .col_5,
  .col_7 {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
  }

  .footer-subscription {
    .footer-menu-block {
      .footer-menu-address {
        width: 100%;
      }
      .footer-icon-out {
        width: 100%;
      }
      .menu-terms-block {
        .menu-list {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          .ft-menu-items {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
          }
        }
        .privacy-list {
          width: 100%;
          .ft-menu-items {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
          }
        }
        .divider-list {
          display: none;
        }
      }
      .footer-menu-address {
        .cl-40,
        .cl-30 {
          width: 100%;
          padding-left: 0px;
          padding-right: 0px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .home_bg_1,
  .home_bg_2,
  .home_bg_3,
  .home_bg_4,
  .home_bg_5,
  .home_bg_6,
  .home_bg_7,
  .navbar-container .round-in-header,
  .get-img-block {
    display: none;
  }
  .home-bg {
    min-height: 320px;
  }
  .pricing-bg-1 {
    min-height: 235px;
    margin-bottom: 30px;
  }
  .session_block {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .blue-btn,
  .blue-btn:hover,
  .blue-btn:focus,
  .red-btn,
  .red-btn:hover,
  .red-btn:focus,
  .sbmit-outer .submit-red-btn,
  .sbmit-outer .submit-red-btn:hover,
  .sbmit-outer .submit-red-btn:focus {
    @include fontSize(13px);
    min-width: auto;
  }
  .logo-img {
    height: 40px;
  }
  .show-icon-banner {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .nav-links {
    padding: 0.5rem 0rem;
  }
  .nav-item {
    height: 40px;
  }
  .navbar-container .nav-menu {
    margin-right: 0px;
    margin-left: 0px;
    padding: 0;
    height: auto;
    top: 70px;
  }
  .navbar,
  .navbar-container {
    height: 80px;
    margin-top: 0;
    padding-top: 0;
  }
  .pricing-tab {
    .pricing-content-1,
    .pricing-content-2 {
      width: 100%;
      margin-bottom: 15px;
      padding: 5px 10px;
    }
    .main-title,
    .main-title-bule {
      @include fontSize(22px);
      padding-bottom: 15px;
      margin-bottom: 5px;
    }
    .main-lg-title {
      @include fontSize(14px);
    }
    .main-sm-title {
      @include fontSize(12px);
    }
    .free-acess {
      @include fontSize(12px);
      margin-top: 15px;
    }
    .pricing-tab .buy-button {
      margin-top: 15px;
      margin-bottom: 5px;
    }
    .button-block {
      height: 38px;
      .price {
        @include fontSize(14px);
        line-height: 14px;
      }
      .validity {
        @include fontSize(12px);
        line-height: 13px;
      }
    }
  }
  .gst-note {
    @include fontSize(14px);
    line-height: 13px;
    padding-bottom: 20px;
    margin-top: 0px;
  }
  .custom-tab {
    .nav-tabs {
      width: 100%;
      padding: 0 10px;
      margin-top: 0px;
      margin-bottom: 40px;
      height: 3rem;
      .nav-link {
        height: 2.2rem;
        @include fontSize(16px);
      }
    }
  }
  .pricing-accordion-section {
    padding-bottom: 30px;
    padding-top: 30px;
    .accordion-item {
      padding: 10px 15px 10px 15px;
      margin-bottom: 10px;
      .accordion-body {
        @include fontSize(14px);
        padding-right: 15px;
      }
      .accordion-button {
        padding: 0px;
        @include fontSize(16px);
      }
      .accordion-button.collapsed::after {
        width: 1rem;
        height: 1rem;
        background-size: 1rem;
      }
    }
  }
  .faq {
    @include fontSize(17px);
  }
  .main_container,
  .main_container-1 {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .main_container-1 {
    margin-left: 0;
  }
  .pricing-section {
    margin-bottom: 0px;
  }
  .pricing-main-title {
    @include fontSize(25px);
  }
  .footer-outer-block,
  .contact-bg {
    background-image: none;
  }
  .slider-text-block {
    height: auto;
    min-height: 200px;
  }
  .pricing-bg-3,
  .pricing-bg-2 {
    min-height: 270px;
    height: auto;
  }
  .get-tuch-title {
    @include fontSize(25px);
    margin-bottom: 2rem;
  }
  .custom-input {
    margin-bottom: 15px;
    height: 35px;
  }
  .sbmit-outer {
    padding-bottom: 30px;
  }
  .contact-bg {
    height: auto;
  }
  .address-icon {
    margin-right: 15px;
    margin-left: 10px;
  }
  .footer-icon {
    margin-top: 10px;
  }
  .location-title {
    @include fontSize(25px);
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .contact-row .contact-col-6 {
    width: 100%;
    margin-bottom: 10px;
    .office-title {
      @include fontSize(20px);
      margin-bottom: 10px;
    }
  }
  .footer-subscription .banner-logo-img {
    height: 3rem;
  }
  .download-img-block {
    flex-wrap: wrap;
    min-height: 520px;
  }
  .slider-text {
    width: 100%;
  }
  .display-img-bg {
    display: none;
  }
  .login-mobile-show {
    display: block;
    .display-img-text1 {
      padding-top: 10px;
    }
    .display-img-text2 {
      color: #4875e8;
    }
  }
  .modal-1280 .social-icon.social-fb-icon .social-icon {
    margin-left: 0px;
  }
  .home-slide-66 {
    .slick-list {
      max-width: 100%;
    }
    .banner-txt-slider {
      min-height: 190px;
      .banner-suubmain-title {
        width: 70%;
      }
    }
  }
  .show-icon-banner .banner-block {
    width: 35px;
    .banner-icon-img {
      width: 35px;
    }
  }
  .show-icon-banner.slick-dots li {
    width: 35px;
    height: 35px;
  }
  .testimonials-slider {
    .slider-card {
      min-height: 440px;
      .slider-text {
        min-height: 330px;
      }
    }
  }
  .testimonials-title {
    @include fontSize(30px);
    margin-bottom: 30px;
  }
  .two-doth-img {
    display: none;
  }
  .logout-buttons {
    .goto-dashboard.btn.red-btn {
      white-space: nowrap;
      padding: 0 10px;
      height: 32px;
      max-width: 150px;
    }
  }
  .logout-icon {
    height: 25px;
    width: 25px;
  }
  .navbar-logo.active {
    width: 40px;
    overflow: hidden;
  }
  .menu-icon {
    margin-right: auto;
    margin-left: 16px;
  }
  .logout-btnn-head {
    padding: 2px 0px 2px 10px !important;
  }
  .ft-menu-items .address-icon {
    margin-left: 3px;
  }
  .navbar {
    .ten-days-ontop {
      position: absolute;
      left: 0;
      right: 0;
      width: calc(100% + 20px);
      top: 80px;
      background-color: #fff;
      margin-left: -10px;
      margin-right: -10px;
      padding: 10px 0 10px 10px;
      -webkit-box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2);
      // border-top: 1px solid #ddd;
    }
  }
  .pricing-slider-top {
    margin-top: 50px;
  }
}
