// @import "~bootstrap/scss/bootstrap";
@font-face {
  font-family: "montserratbold";
  src: url("./fonts/montserrat-bold-webfont.eot");
  src: url("./fonts/montserrat-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-bold-webfont.woff") format("woff"),
    url("./fonts/montserrat-bold-webfont.ttf") format("truetype"),
    url("./fonts/montserrat-bold-webfont.svg#montserratbold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratregular";
  src: url("./fonts/montserrat-regular-webfont.eot");
  src: url("./fonts/montserrat-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/montserrat-regular-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-regular-webfont.woff") format("woff"),
    url("./fonts/montserrat-regular-webfont.ttf") format("truetype"),
    url("./fonts/montserrat-regular-webfont.svg#montserratregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratbold_italic";
  src: url("./fonts/montserrat-bolditalic-webfont.eot");
  src: url("./fonts/montserrat-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/montserrat-bolditalic-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-bolditalic-webfont.woff") format("woff"),
    url("./fonts/montserrat-bolditalic-webfont.ttf") format("truetype"),
    url("./fonts/montserrat-bolditalic-webfont.svg#montserratbold_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratlight";
  src: url("./fonts/montserrat-light-webfont.eot");
  src: url("./fonts/montserrat-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/montserrat-light-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-light-webfont.woff") format("woff"),
    url("./fonts/montserrat-light-webfont.ttf") format("truetype"),
    url("./fonts/montserrat-light-webfont.svg#montserratlight") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratsemibold";
  src: url("./fonts/montserrat-semibold-webfont.eot");
  src: url("./fonts/montserrat-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/montserrat-semibold-webfont.woff2") format("woff2"),
    url("./fonts/montserrat-semibold-webfont.woff") format("woff"),
    url("./fonts/montserrat-semibold-webfont.ttf") format("truetype"),
    url("./fonts/montserrat-semibold-webfont.svg#montserratsemibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./fonts/montserrat-medium.eot");
  src: url("./fonts/montserrat-medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/montserrat-medium.woff2") format("woff2"),
    url("./fonts/montserrat-medium.woff") format("woff"),
    url("./fonts/montserrat-medium.ttf") format("truetype"),
    url("./fonts/montserrat-medium.svg#montserrat-medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Condensed";
  src: url("./fonts/robotocondensed-bold-webfont.eot");
  src: url("./fonts/robotocondensed-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/robotocondensed-bold-webfont.woff2") format("woff2"),
    url("./fonts/robotocondensed-bold-webfont.woff") format("woff"),
    url("./fonts/robotocondensed-bold-webfont.ttf") format("truetype"),
    url("./fonts/robotocondensed-bold-webfont.svg#Roboto-Condensed")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Flex";
  src: url("./fonts/robotoflexgradxopqxtrayopqytasytdeytfiytlcytucopszslntwdthwght-webfont.eot");
  src: url("./fonts/robotoflexgradxopqxtrayopqytasytdeytfiytlcytucopszslntwdthwght-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/robotoflexgradxopqxtrayopqytasytdeytfiytlcytucopszslntwdthwght-webfont.woff2")
      format("woff2"),
    url("./fonts/robotoflexgradxopqxtrayopqytasytdeytfiytlcytucopszslntwdthwght-webfont.svg#Roboto-Flex")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@media only screen and (min-width: 1837px) {
  html {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1537px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1536px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1366px) {
  html {
    font-size: 13px;
  }
}
$basesize: 16px;
@function calculateRem($size) {
  $remSize: $size / $basesize;
  @return #{$remSize}rem;
}
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
@mixin fontSizeimp($size) {
  font-size: $size !important;
  font-size: calculateRem($size) !important;
}
.classname {
  @include fontSize(18px);
}
.banner-main-title {
  padding-top: 4.5rem;
  width: 66%;
  @include fontSize(58px);
  //line-height: 60px;
  font-family: "montserratbold";
  letter-spacing: -0.5px;
}
.banner-suubmain-title {
  width: 50%;
  padding-top: 3.5rem;
  padding-bottom: 3rem;
  @include fontSize(27px);
  line-height: 33px;
}
.institutes-slider {
  .banner-main-title {
    padding-top: 4.5rem;
    width: 100%;
    @include fontSize(58px);
    //line-height: 60px;
    font-family: "montserratbold";
    letter-spacing: -0.5px;
  }
  .banner-suubmain-title {
    width: 72%;
    padding-top: 3.5rem;
    padding-bottom: 3rem;
    @include fontSize(30px);
    line-height: 33px;
  }
}
.slider-text {
  width: 66%;
}
.slider-img {
  width: 44%;
  .pricing_icon_img {
    width: 100%;
  }
}
.full-width-card {
  width: 100%;
}
.ten-days-ontop {
  padding-right: 10px;
}
.slider-text-block {
  min-height: 60vh;
  display: flex;
  flex-wrap: wrap;
}
.download-img-block {
  display: flex;
  width: 100%;
}
.download-btn-block {
  width: 100%;
  display: flex;
  margin-top: -100px;
  .slider-img {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
.blue-btn,
.blue-btn:focus {
  background-color: #0538bc !important;
  // padding: 10px 30px 10px 30px;
  color: #fff !important;
  min-width: 261px;
  @include fontSize(22px);
}
.blue-btn:hover {
  background-color: #072884 !important;
}
.red-btn,
.red-btn:focus {
  background-color: #e72c25 !important;
  // padding: 10px 30px 10px 30px;
  color: #fff !important;
  min-width: 230px;
  @include fontSize(22px);
}
.red-btn:hover {
  background-color: #940c07 !important;
}
.navbar-container {
  position: relative;
  // overflow: hidden;
  .round-in-header {
    background-color: #dcedc8;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    top: -78px;
    left: 55%;
    width: 120px;
    margin: 0 auto;
    z-index: -1;
  }
}
.logo-img {
  height: 62px;
}
.nav-links {
  @include fontSize(22px);
}
.show-icon-banner {
  width: 50%;
  margin-top: 100px;
  display: flex;
  margin-bottom: 20px;
  .banner-block {
    display: block;
    width: 55px;
    margin-right: 20px;
    .banner-icon-img {
      width: 55px;
    }
  }
}
.show-icon-banner.slick-dots li {
  width: 55px;
  height: 55px;
}
.slick-dots.slick-thumb.show-icon-banner {
  margin-top: 120px;
  text-align: left;
  bottom: auto;
  display: flex !important;
}
.active-icon {
  display: none;
}
.show-icon-banner.slick-dots li.slick-active .active-icon {
  display: flex;
}
.show-icon-banner.slick-dots li.slick-active .deactive-icon {
  display: none;
}
/*footer css*/
.footer-outer-block {
  background-color: #f8faff;
  padding: 40px 0 20px 0;
  background-image: url("./images/cat/footer-bg.png");
  background-position: 90px 40px;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 88%;
  margin: 0 auto;
}
.bottom-footer {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000c2d;
  .copy-right-footer {
    @include fontSize(16px);
    color: #fff;
    font-family: "montserratlight";
  }
}
.full-size {
  width: 100%;
}
.home-page {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.session_block {
  background-color: #fbfbfb;
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
  .flex-row {
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
  }
  .video_block {
    padding-right: 40px;
    .video_player {
      height: 440px;
      width: 100%;
      // background-color: #FAD4D3;
      border-radius: 10px;
    }
  }
  .text_block {
    padding-left: 40px;
    .title_view {
      display: flex;
      align-items: center;
      .title_icon {
        .iconicon {
          height: 111px;
          width: 111px;
        }
      }
      .title_Text {
        @include fontSize(26px);
        font-family: "montserratbold";
        color: #000000;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .list_view {
      .list_items {
        margin-top: 15px;
        display: list-item;
        margin-left: 1em;
        list-style-type: square;
        @include fontSize(30px);
        .list_data {
          @include fontSize(22px);
          color: #000000;
        }
        .list_data1 {
          @include fontSize(17px);
          color: #000000;
        }
      }
      .list_items_new {
        list-style-type: disc;
        margin-top: 10px;
        font-size: 1.4rem !important;
      }
    }
  }
}
.session_block-2 {
  background-color: #fff;
  .flex-row {
    flex-direction: row-reverse !important;
  }
  .video_block {
    padding-left: 40px;
    padding-right: 0px;
  }
  .text_block {
    padding-right: 40px;
    padding-left: 0px;
  }
}
.institude-session {
  .flex-row {
    align-items: center;
  }
  .video_block {
    .video_player {
      // background-color: #E8EEFF;
    }
  }
}
.session_block-3 {
  background-color: #ffe8c7;
  padding-top: 70px;
}
.session_block-321 {
  background: #fbf7ed;
}
.session_block-3214 {
  background: #fbf8f8;
}
.session_block-32145 {
  background: #e8eefe;
  min-height: 88vh !important;
  display: flex;
  align-items: center;
}
.session_block-w {
  width: 90%;
}
.session_block-32146 {
  background: #eafee8;
  min-height: 88vh !important;
  display: flex;
  align-items: center;
}
.session_block-321467 {
  background: #fef0e8;
  min-height: 88vh !important;
  display: flex;
  align-items: center;
}
.learn-more-btn {
  border: 2px solid #e82920;
}
.banner-txt-img {
  height: 75px;
  width: 75px;
}
.list_v_1 {
  color: #ff9802;
}
.list_v_2 {
  color: #e72c25;
}
.list_v_3 {
  color: #8bc34a;
}
.list_v_4 {
  color: #f39c6a;
}
.list_v_5 {
  color: #0538bc;
}
/*base style*/
.main_container {
  width: 88%;
  margin: 0 auto;
}
.main_container-1 {
  width: 94%;
  margin-left: 6%;
}
.col_6 {
  width: 50%;
}
/*base style*/
.footer-banner-img {
  width: 100%;
  border-radius: 6px;
  position: relative;
  z-index: 2;
}
.footer-subscription {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .banner-logo-img {
    height: 5rem;
  }
  .footer-logo {
    width: 30%;
  }
  .footer-menu-block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 70%;
    .menu-terms-block {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 30px;
      // border-bottom: 1px solid #899EBD;
      .privacy-list {
        display: flex;
        flex-wrap: wrap;
      }
      .menu-list {
        // display: flex;
        flex-wrap: wrap;
        width: 33.33%;
      }
    }
    .footer-menu-address {
      display: flex;
      flex-wrap: wrap;
      width: 33.33%;
      align-items: flex-start;
      .ft-menu-items {
        align-items: flex-start;
        line-height: 24px;
      }
    }
  }
}
.footer-icon-out {
  width: 33.33%;
}
.ft-menu-items {
  @include fontSize(18px);
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 14px;
  .address-icon {
    margin-right: 17px;
    margin-left: 10px;
  }
}
.ft-menu-items.active {
  color: #e72c25;
}
.menu-list .ft-menu-items:hover {
  color: #e72c25;
}
.divider-list {
  background-color: #899ebd;
  height: 20px;
  width: 2px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  margin-left: 10px;
}
.cl-40 {
  width: 55%;
  align-items: flex-start;
}
.cl-30 {
  align-items: flex-start;
}
.slider-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  // height: 450px;
  min-height: 570px;
  background-color: #fff;
  // padding: 20px;
  padding: 20px 20px 30px 20px;
  position: relative;
  margin-bottom: 10px;
  .slider-text {
    @include fontSize(20px);
    color: #4d4d4d;
    min-height: 390px;
    width: 100%;
  }
  .metro_quote {
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: -10px;
    right: 25px;
  }
}

.slick-slide-outer .slick-slide {
  padding: 0 20px;
  margin-top: -2px;
  padding-top: 51px;
}
.bannerSlider-outer {
  overflow: hidden;
}
.profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 120px;
    width: 120px;
    margin: 0 auto;
    margin-top: -65px;
    border-radius: 50%;
    margin-bottom: 30px;
    object-fit: contain;
    background-color: #dcedc8;
  }
}
.Standard {
  @include fontSize(15px);
  color: #919191;
  font-family: "montserratbold_italic";
}
.reviewer-name {
  @include fontSize(20px);
  color: #111111;
  font-family: "montserratbold_italic";
}
.testimonials-title {
  color: #111111;
  @include fontSize(50px);
  font-family: "montserratbold";
  margin-bottom: 70px;
  text-align: center;
}
.custom-input {
  width: 100%;
  height: 42px;
  border: 1px solid rgba(62, 62, 62, 0.18);
  background-color: transparent;
  outline: none;
  @include fontSize(18px);
  color: #3e3e3e;
  margin-bottom: 30px;
  padding: 0 15px;
}
.custom-text-area {
  width: 100% !important;
  height: 130px !important;
  border: 1px solid rgba(62, 62, 62, 0.18);
  background-color: transparent;
  outline: none;
  @include fontSize(18px);
  color: #3e3e3e;
  margin-bottom: 30px;
  padding: 10px 15px;
  font-family: "montserratregular" !important;
  resize: none;
}
.get-tuch-title {
  color: #111111;
  @include fontSize(50px);
  font-family: "montserratbold";
  padding-top: 4.5rem;
  margin-bottom: 3rem;
}
.contact-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4.5rem;
  .contact-col-6 {
    margin-left: 10px;
    margin-right: 10px;
    padding: 25px;
    background-color: rgba(255, 232, 199, 0.47);
    width: 35%;
    // height: 224px;
    .office-title {
      color: #000;
      @include fontSize(30px);
      font-family: "montserratbold";
      margin-bottom: 20px;
    }
    .office-addr {
      display: flex;
      .address-icon {
        margin-right: 10px;
        .location-img {
          width: 16px;
          height: 22px;
        }
        .address-text {
          color: #111111;
          @include fontSize(20px);
        }
      }
    }
  }
}
.location-title {
  margin-top: 4rem;
  color: #111111;
  @include fontSize(50px);
  font-family: "montserratbold";
  margin-bottom: 3rem;
  text-align: center;
}
.modal-1280 {
  // max-width: 1280px;
  // min-width: 1280px;
  max-width: 1025px;
  min-width: 1025px;
  .modal-content {
    border-radius: 12px;
  }
}
.resend-code {
  background-color: white;
  border: white;
  color: red;
}

button:disabled,
.resend-code button[disabled] {
  background-color: white;
  border: white;
  // color: rgba(255, 0, 0, 0.3);
  color: grey;
}
.stud-img {
  height: 34rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
  object-fit: cover;
}
.signup-img {
  min-height: 33rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 102%;
  object-fit: cover;
}
.padding-lf-0 {
  padding-left: 0px;
  background-color: #0538bc;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.modal-header-cust {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.detail-block {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  min-height: 550px;
  margin-bottom: 10px;
  position: relative;
  &.detail-block-resend {
    min-height: 390px;
    .mr-top-50 {
      margin-top: 80px;
    }
  }
}
.two-doth-img {
  position: absolute;
  right: 0;
  height: 110px;
  top: 50%;
}
.form-block {
  margin-top: 20px;
  padding-right: 60px;
}
.popup-logo-img {
  height: 3.3rem;
}
.popup-close-img {
  height: 1.6rem;
  width: 1.6rem;
}
.btn-full-size,
.btn-full-size:hover,
.btn-full-size:focus {
  min-width: 100%;
  margin-bottom: 20px;
}
.btn {
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 0.2px;
  font-weight: 600;
}
.font-icon {
  position: absolute;
  right: 11px;
  bottom: 5px;
  z-index: 9999;
  font-size: 20px;
  color: #0538bc;
}
.cat-form-title {
  color: #0538bc;
  @include fontSize(21px);
  font-family: "montserratbold";
  margin-bottom: 12px;
}
.cat-form-title-bloack {
  color: #000;
  @include fontSize(19px);
  font-family: "montserratbold";
  margin-bottom: 12px;
}
.cat-form-title-bloacks {
  color: #000;
  @include fontSize(19px);
  font-family: "montserratbold";
  margin-bottom: 12px;
}
.re-enter-pass {
  margin-top: 15px;
  position: relative;
  input.form-control {
    padding-right: 45px;
  }
}
.form-min-block-1 .mb-3 {
  position: relative;
}
.modal-1280 {
  .error-msg {
    // bottom: -18px;
    top: 6px;
  }
}
.input-icon {
  position: relative;
}
input.form-control {
  color: #000;
  @include fontSize(15px);
  font-family: "montserratbold";
  height: 38px;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
}
.send-code-row {
  display: flex;
  margin-top: 32px;
  justify-content: flex-start;
  align-items: flex-start;
  .edit-img {
    height: 28px;
    width: 28px;
  }
  .cat-email-sub-title {
    color: #0538bc;
    @include fontSize(15px);
    font-family: "montserratbold";
    margin-bottom: 7px;
  }
  .span-verification-code {
    display: flex;
    flex-wrap: wrap;
  }
  .email-span {
    white-space: nowrap;
  }
  .email-img-span {
    white-space: nowrap;
    display: flex;
  }
  .padding-text {
    padding-right: 4px;
  }
}
.timer-code-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .dont-send {
    color: #707c8a;
    @include fontSize(16px);
    span {
      color: #e72c25;
    }
  }
  .timer {
    color: #707c8a;
    @include fontSize(16px);
  }
}
.error-msg {
  color: #e72c25;
  @include fontSize(13px);
  position: relative;
  // bottom: 5px;
}
.error-msg-10 {
  @include fontSize(10px);
}
.input-label {
  font-size: smaller;
  color: #a1a1a1;
}
.target-input-label {
  font-weight: 800;
  font-size: smaller;
  color: #a1a1a1;
}
.display-img-bg {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}
.display-img-text1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #4875e8;
  align-items: center !important;
  padding-top: 40px;
  text-align: center;
  padding-left: 10px;
}
.signup-bg-img-stud {
  position: relative;
  background-image: url("./images/cat/student-login-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #0431c4;
  background-position: bottom;
  border-radius: 12px 0 0px 12px;
}
.modal-content .signup-bg-img-stud {
  background-size: 101%;
}
.signup-bg-img-parent {
  position: relative;
  background-image: url("./images/cat/parent-login.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #0431c4;
  background-position: bottom;
  border-radius: 12px 0 0px 12px;
}
.login-bg-img-stud {
  position: relative;
  background-image: url("./images/cat/stu.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #0431c4;
  background-position: bottom;
  border-radius: 12px 0 0px 12px;
}
.otp-bg-img-stud {
  position: relative;
  background-image: url("./images/cat/otp-screen.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0431c4;
  background-position: right bottom;
  border-radius: 12px 0 0px 12px;
}
.display-img-text2 {
  font-family: "Roboto Condensed", sans-serif;
  // font-weight: bold;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.cat-form-sub-title {
  color: #000;
  @include fontSize(18px);
  font-family: "montserratbold";
  margin-bottom: 12px;
}
ß .form-min-block {
  min-height: 20rem;
}
.forgot-pass {
  color: #0538bc;
  margin-bottom: 10px;
}
.no-margin {
  margin-bottom: 15px !important;
}
.or-login-text {
  color: #0538bc;
  position: relative;
  text-align: center;
}
.or-login-text:before {
  content: "";
  display: block;
  width: 32%;
  height: 1px;
  background: #000000;
  left: 0;
  top: 50%;
  position: absolute;
}
.or-login-text:after {
  content: "";
  display: block;
  width: 32%;
  height: 1px;
  background: #000000;
  right: 0;
  top: 50%;
  position: absolute;
}
.social-signup {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  .flex-social-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .flex-social-box.fb-hide {
    justify-content: space-evenly !important;
  }
}
.social-icon {
  width: 126px;
  height: 40px;
  // margin: 0 8px;
}
.drop-down-outer {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  .form-select {
    width: 100%;
  }
}
.reset-pass {
  .std-bold-radio {
    .drop-down-outer {
      margin-bottom: 0;
    }
  }
}

.width-100 {
  width: calc(50% - 10px);
}
.pricing-bg-1 {
}
.pricing-main-title {
  padding-top: 4.5rem;
  width: 66%;
  @include fontSize(65px);
  font-family: "montserratbold";
}
.pricing-icon-banner {
  width: 60%;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .pricing-block {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    .pricing-icon-img {
      height: 65px;
      width: 65px;
    }
    .pricing-sub-title {
      @include fontSize(23px);
      font-family: "montserratsemibold";
      padding-left: 10px;
      color: #000000;
    }
  }
}
.custom-tab {
  .nav-tabs {
    border: none;
    width: 58rem;
    margin: 0 auto;
    background: #8bc34a;
    border-radius: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-top: 80px;
    margin-bottom: 40px;
    .nav-item {
      height: auto;
      width: 50%;
      outline: none !important;
    }
    .nav-item:focus-visible,
    .nav-link:focus-visible {
      outline: none !important;
    }
    .nav-link {
      width: 100%;
      height: 3.25rem;
      border-radius: 3.25rem;
      border: none !important;
      color: #fff;
      @include fontSize(23px);
      font-family: "Roboto Condensed", sans-serif;
    }
    .nav-link:hover,
    .nav-link:focus {
      border: none !important;
    }
    .nav-link.active {
      border: none !important;
      color: #8bc34a;
    }
  }
}
.pricing-section {
  margin-bottom: 60px;
}
.pricing-accordion-section {
  padding-bottom: 70px;
  padding-top: 70px;
  background-color: #ffe8c7;
  .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
  }
  .accordion-item {
    border: none;
    margin-bottom: 28px;
    padding: 10px 20px 10px 50px;
    .accordion-button {
      @include fontSize(28px);
      font-family: "montserratsemibold";
      outline: none !important;
      box-shadow: none !important;
    }
    .accordion-body {
      @include fontSize(22px);
      padding-right: 45px;
    }
    .accordion-button::after {
      opacity: 0.3;
      background-size: 1.75rem;
      width: 1.75rem;
      height: 1.75rem;
      background-image: url("./images/cat/down-arr.svg");
    }
    .accordion-button.collapsed::after {
      background-size: 1.75rem;
      width: 1.75rem;
      height: 1.75rem;
      background-image: url("./images/cat/down-arr.svg");
      opacity: 1;
    }
  }
}
.terms-of-service {
  padding-bottom: 70px;
  padding-top: 70px;
  background-color: white;
}
.faq {
  color: #111111;
  @include fontSize(50px);
  font-family: "montserratbold";
  margin-bottom: 40px;
  text-align: center;
}
.privacy-policy {
  //width: 88%;
  margin: 0 auto;
  padding-bottom: 15px;
  .main-title {
    color: #111111;
    @include fontSize(35px);
    font-family: "montserratbold";
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #0538bc;
  }
  .main-lg-title {
    color: #111111;
    @include fontSize(20px);
    font-family: "montserratbold";
    margin-bottom: 10px;
    padding-bottom: 15px;
  }
  .left-padding {
    padding-left: 20px;
  }
}
.pricing-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .pricing-content-1 {
    border: 1px solid #0538bc;
    border-radius: 10px;
    width: calc(50% - 30px);
    padding: 25px;
  }
  .pricing-content-2 {
    border-radius: 10px;
    width: calc(50% - 30px);
    padding: 25px 35px;
    background-color: #e8eeff;
  }
  .main-lg-title {
    color: #111111;
    @include fontSize(20px);
    font-family: "montserratbold";
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .main-sm-title {
    color: #111111;
    @include fontSize(16px);
    margin-bottom: 7px;
    display: list-item;
    margin-left: 1.3rem;
    list-style-type: disc;
  }
  .main-title {
    color: #111111;
    @include fontSize(35px);
    font-family: "montserratbold";
    padding-bottom: 25px;
    margin-bottom: 15px;
    border-bottom: 1px solid #0538bc;
  }
  .main-title-bule {
    color: #0538bc;
    @include fontSize(35px);
    font-family: "montserratbold";
    padding-bottom: 25px;
    margin-bottom: 15px;
    border-bottom: 1px solid #0538bc;
  }
  .button-block {
    height: 55px;
    width: 165px;
    border-radius: 35px;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    flex-direction: column;
    margin-right: 15px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    .price {
      @include fontSize(20px);
      line-height: 22px;
      font-family: "montserratbold";
    }
    .validity {
      @include fontSize(14px);
      line-height: 18px;
      font-family: "montserratbold";
    }
  }
  .button-block:last-child {
    margin-right: 0px;
  }
  .button-block.active {
    background-color: #0538bc;
    color: #fff;
  }
  .price-row {
    display: flex;
  }
  .price-btns-row {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    height: 70px;
  }
  .free-acess {
    @include fontSize(16px);
    line-height: 18px;
    font-family: "montserratbold";
    color: #0538bc;
    margin-top: 1.5rem;
  }
  .buy-button {
    height: 42px;
    width: 100%;
    border-radius: 0px;
    background-color: #0538bc;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    flex-direction: column;
    margin-right: 15px;
    color: #fff;
    text-decoration: none;
    @include fontSize(18px);
    font-family: "montserratsemibold";
    margin-top: 35px;
    margin-bottom: 10px;
  }
  .disabled-buy-button {
    height: 42px;
    width: 100%;
    border-radius: 0px;
    background-color: #8d93a1;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    flex-direction: column;
    margin-right: 15px;
    color: #fff;
    text-decoration: none;
    @include fontSize(18px);
    font-family: "montserratsemibold";
    margin-top: 35px;
    margin-bottom: 10px;
  }
}
.gst-note {
  @include fontSize(18px);
  text-align: right;
  color: #000000;
  margin-top: 10px;
  padding-bottom: 30px;
}
.download-brochure {
  display: flex;
  margin-right: 10px;
}
.get-tuch {
  background-color: #e8eeff;
  .get-tuch-title {
    padding-top: 0px;
  }
  .get-details-block {
    padding-right: 40px;
  }
  .get-img-block {
    padding-left: 40px;
    text-align: right;
    img {
      max-width: 100%;
      max-height: 700px;
    }
  }
  .pricing_contact {
    max-width: 100%;
  }
  .sbmit-outer {
    padding-bottom: 0px;
  }
}
.for-get-tuch {
  background-color: #fff;
}
.flex-row-block {
  display: flex;
  .pricing_contact {
    width: 100%;
    max-height: 690px;
    object-fit: contain;
  }
}
.institude-session.institude-session-1 {
  padding-bottom: 5px;
}
.sbmit-outer {
  padding-bottom: 60px;
  .submit-red-btn,
  .submit-red-btn:focus {
    background-color: #e72c25 !important;
    color: #fff !important;
    min-width: 230px;
    @include fontSize(22px);
  }
  .submit-red-btn:hover {
    background-color: #940c07 !important;
  }
}
.home_bg_1 {
  position: absolute;
  height: 370px;
  top: 20rem;
  left: -12px;
  z-index: 0;
}
.home_bg_2 {
  position: absolute;
  z-index: 0;
  height: 300px;
  top: -20px;
  right: 0px;
}
.home_bg_3 {
  position: absolute;
  z-index: 0;
  height: 120px;
  top: 50%;
  left: -80px;
}
.home_bg_4 {
  position: absolute;
  z-index: 1;
  height: 140px;
  top: 88%;
  right: -20px;
}
.home_bg_5 {
  position: absolute;
  z-index: 1;
  height: 450px;
  bottom: -14%;
  left: -5px;
}
.home_bg_6 {
  position: absolute;
  z-index: 1;
  height: 180px;
  bottom: -85px;
  left: 60%;
}
.home_bg_7 {
  position: absolute;
  z-index: 1;
  height: 300px;
  bottom: 40px;
  right: 0;
}
.home-img-bg-3 {
  position: relative;
}
.home_bg_34 {
  position: absolute;
  z-index: -1;
  height: 120px;
  top: 28%;
  left: -80px;
}
.terms-text {
  margin-bottom: 16px;
  font-size: 20px;
}
.footer-icon {
  display: flex;
  padding-left: 3px;
  padding-right: 15px;
  a {
    display: flex;
    width: 35px;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 25px;
  }
}
.footer-icon-line {
  margin-right: 30px;
}
.icon-call-msg {
  display: flex;
  align-items: center;
  .call-img {
    height: 16px;
  }
  .mail-img {
    height: 12px;
  }
  span {
    display: flex;
    width: 25px;
  }
  a {
    color: #000000;
    text-decoration: none;
  }
  img {
    height: 20px;
    margin-right: 6px;
  }
}
.underline {
  text-decoration: underline;
}
.download-btn {
  display: flex;
  // position: absolute;
  // right: 66px;
  // bottom: 65px;
  margin-left: 15px;
  height: 40px;
  img {
    width: 40px;
    object-fit: cover;
  }
}
.white-btn,
.white-btn:hover,
.white-btn:focus {
  background-color: #fff !important;
  // padding: 10px 30px 10px 30px;
  color: #e72c25 !important;
  font-family: "montserratsemibold";
  letter-spacing: -0.5px;
  min-width: 195px;
  @include fontSize(16px);
  padding: 8px 8px;
}

.col_5 {
  width: 45%;
}

.col_7 {
  width: 55%;
}
.mr-top-50 {
  margin-top: 135px;
}
.disabled {
  background-color: grey;
}
.download-outer {
  position: relative;
  // width: 39%;
  // background: red;
  margin-top: 5px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}
.logout-buttons {
  display: flex;
}
.pricing-tab .free-acess-neet {
  @include fontSize(16px);
  line-height: 18px;
  font-family: "montserratbold";
  color: #0538bc;
  margin-top: 1.5rem;
}
.logout-icon {
  height: 30px;
  width: 30px;
}
.gif-video {
  height: 100%;
  max-height: 327px;
  object-fit: contain;
}
.get-img-block-outer {
  position: relative;
  overflow: hidden;
  .pricing_contact {
    z-index: 2;
    position: relative;
  }
  .contact-bg-color {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: calc(100% - 40px);
    background: #fad4d3;
    left: 40px;
  }
}
.social-fb-icon {
  //margin-left: 0;
  border: none;
  .social-icon {
    margin-left: 0;
  }
}
.login-mobile-show {
  display: none;
}
.btn-no-border {
  border: none;
  padding: 0;
  background: none;
  border-bottom: 1px solid #bbb;
}
.package-block {
  min-height: 325px;
}
.pricing-tab {
  .Free-Lifetime-Access {
    margin-top: 106px;
  }
  .free-acess-neet.Free-Lifetime-Access {
  }
}
.home-slide-66 {
  .slick-list {
    max-width: 66%;
  }
  .banner-txt-slider {
    min-height: 440px;
    max-width: 100%;
    .banner-main-title {
      width: 100%;
    }
    .banner-suubmain-title {
      width: 90%;
    }
  }
}

.banner-btn-div {
  height: 50px;
}
.testimonials-slider {
  .slick-list {
    width: 90%;
    margin: 0 auto;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 35px;
    color: #ff9802;
  }
  .slick-next {
    right: 2%;
    width: 40px;
    height: 40px;
  }
  .slick-prev {
    left: 2%;
    width: 40px;
    height: 40px;
  }
}
.institutes-slider {
  .slick-prev:before,
  .slick-next:before {
    font-size: 35px;
    color: #ff9802;
  }
  .slick-next {
    right: 2%;
    width: 40px;
    height: 40px;
  }
  .slick-prev {
    left: 2%;
    width: 40px;
    height: 40px;
    z-index: 1;
  }
}
.pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
  .error-msg {
    position: relative;
    bottom: 0px;
  }
}
.input-outer {
  position: relative;
}
.student-txt-email {
  color: #0538bc;
  font-size: 14px;
  font-family: "montserratregular";
  margin-bottom: 12px;
}
.loading-button {
  position: relative;
  .spinner-border {
    // position: absolute;
    // right: 15px;
    top: 12px;
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
    --bs-spinner-border-width: 0.2em;
  }
}
.loading-btn-disable {
  position: relative;
  opacity: 0.5;
  pointer-events: none;
  .spinner-border {
    // position: absolute;
    // right: 15px;
    top: 12px;
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
    --bs-spinner-border-width: 0.2em;
  }
}
.std-bold-radio {
  .form-check-label {
    font-family: "montserratbold";
  }
}
::placeholder {
  color: #000 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #000 !important;
}

::-ms-input-placeholder {
  color: #000 !important;
}
.keep-me.mb-3 {
  margin-top: 25px;
  margin-bottom: 30px !important;
}
.mb-3 {
  margin-bottom: 20px !important;
}
.modal-content .mb-3 {
  margin-bottom: 14px !important;
}
.get-tuch {
  .input-outer {
    margin-bottom: 25px;
  }
  .custom-input,
  .custom-text-area {
    margin-bottom: 6px;
  }
}
.contact-bg {
  .input-outer {
    margin-bottom: 25px;
  }
  .custom-input,
  .custom-text-area {
    margin-bottom: 6px;
  }
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-tj5bde-Svg {
  fill: #0538bc !important;
}
.css-tlfecz-indicatorContainer {
  color: #0538bc !important;
}
.css-14el2xx-placeholder,
.css-qc6sy-singleValue {
  font-family: "montserratbold";
  @include fontSize(15px);
  color: #000 !important;
}
.css-26l3qy-menu {
  margin-top: 0px !important;
}
.drop-down-outer .css-1s2u09g-control,
.css-1pahdxg-control {
  outline: none !important;
  box-shadow: none !important;
}
.student-signup-outer {
  display: flex;
  flex-wrap: wrap;
  .send-code-row {
    margin-top: 0;
  }
  .cat-form-sub-title {
    margin-bottom: 0;
    margin-top: -2px;
  }
}
.scroll-block {
  max-height: 550px;
  overflow: auto;
  z-index: 99;
  position: relative;
}
.video__player_shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.sw-custom-modal * {
  position: relative;
  z-index: 1;
}
.cursor-pointer {
  cursor: pointer;
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .session_block-32145 {
    min-height: 690px !important;
  }
  .session_block-32146 {
    min-height: 690px !important;
  }
  .session_block-321467 {
    min-height: 690px !important;
  }
  .size567 ul li.li-text:before {
    top: -6px !important;
  }
  .size567 ul li.head-text:before {
    top: -3px !important;
  }
  .size567 ul li.download-li:before {
    top: -17px !important;
  }
}
@media only screen and (max-width: 992px) {
  .size567 ul li.li-text:before {
    top: -6px !important;
  }
  .size567 ul li.head-text:before {
    top: -3px !important;
  }
  .size567 ul li.download-li:before {
    top: -17px !important;
  }
  .session_block-32145 {
    min-height: 800px !important;
  }
  .session_block-32146 {
    min-height: 800px !important;
  }
  .session_block-321467 {
    min-height: 800px !important;
  }
}

@import "./responsive";
