* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'montserratregular';
}
.home,
.services,
.portafolio,
.aboutme {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}



.home-bg {
  background-image: url("./images/cat/home-screen-banner.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 650px;
  background-color: #E8EEFE;
}
.contact-bg {
  /* background-image: url("./images/cat/contact-bg.png"); */
  background-position: 60px center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 600px;
  background-color: #E8EEFE;
}
.pricing-bg-1 {
  background-image: url("./images/cat/pricing-1.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 660px;
  background-color: #FAD3D3;
  background-position: bottom;
  /* height: calc(100vh - 280px); */
}
.pricing-bg-2 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 660px;
  height: calc(100vh - 280px);
  background-color: #DCEDC7;
}
.pricing-bg-3 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 660px;
  height: calc(100vh - 280px);
  background-color: #DCEDC7;
}
.page-content {
  width: 88%;
  margin: 0 auto;
}
@media only screen and (min-width: 1500px) { 
  .pricing-bg-1 {
    min-height: 750px;
  }
  .pricing-bg-1 .pricing-main-title {
    padding-top: 9.5rem;
  }
}
@media only screen and (min-width: 767px) { 
  .home-bg{
    background-position: center bottom;
  }
}
